import React, { useState, useContext, useEffect } from "react";
import "./Header.css";
import logo from "../images/BaczkoCaramics-02-blue.png";
import { FacebookRounded } from "@mui/icons-material";
import { Menu, MenuItem, Button, Box, IconButton } from "@mui/material";
import GlobalContext from "../Store/Context";

const Header = () => {
  const { lang, setLang } = useContext(GlobalContext);

  const [anchorEl, setAnchorEl] = useState(null);
  const [activeLang, setActiveLang] = useState(lang);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (_lang) => {
    if (_lang === "HUN") {
      setActiveLang("HUN");
      setLang("HUN");
    } else if (_lang === "ENG") {
      setActiveLang("ENG");
      setLang("ENG");
    }
    setAnchorEl(null);
  };

  return (
    <>
      <header className="headerBody">
        <div className="titleLogo">
          <img className="logo" src={logo} />
        </div>
        <Box
          className="linksAndLang"
          display="flex"
          gap="1rem"
          alignContent="center"
          alignItems="center"
        >
          <a
            href="https://www.facebook.com/profile.php?id=100064424033099"
            target="_blank"
            rel="noopener noreferrer"
          >
            <IconButton>
              <FacebookRounded
                color="#0d2037"
                sx={{ color: "#0d2037", transform: "scale(1.3)" }}
              />
            </IconButton>
          </a>
          <Button
            variant="text"
            sx={{ color: "#0d2037" }}
            id="lang-selector"
            aria-controls={open ? "lang-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
          >
            {activeLang}
          </Button>
        </Box>
        <Menu
          id="lang-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "lang-selector",
          }}
        >
          <MenuItem onClick={() => handleClose("HUN")}>HUN</MenuItem>
          <MenuItem onClick={() => handleClose("ENG")}>ENG</MenuItem>
        </Menu>
      </header>
    </>
  );
};

export default Header;
