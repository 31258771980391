import React, { Suspense, useRef } from "react";
import "./Home.css";
import {
  Container,
  ImageList,
  ImageListItem,
  Box,
  TextField,
  Button,
  Grid,
  Snackbar,
  Alert,
  Backdrop,
} from "@mui/material";
import aboutImg from "../images/anderaProfile.jpg";
import landingLogo from "../images/BaczkoCaramics-02-blue.png";
import aboutProdImage from "../images/aboutProdImage.jpg";
import $ from "jquery";
import { useState, useContext, useEffect } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import GlobalContext from "../Store/Context";
import TextLibrary from "../Store/Text";
import Lightbox from "../components/Ligthbox";

import image1 from "../images/GalleryImages/1.jpg";
import image2 from "../images/GalleryImages/2.jpg";
import image3 from "../images/GalleryImages/3.jpg";
import image4 from "../images/GalleryImages/4.jpg";
import image5 from "../images/GalleryImages/5.jpg";
import image6 from "../images/GalleryImages/6.jpg";
import image7 from "../images/GalleryImages/7.jpg";
import image8 from "../images/GalleryImages/8.jpg";
import image9 from "../images/GalleryImages/9.jpg";
import image10 from "../images/GalleryImages/10.jpg";
import image11 from "../images/GalleryImages/11.jpg";
import image12 from "../images/GalleryImages/12.jpg";
import image13 from "../images/GalleryImages/13.jpg";
import image14 from "../images/GalleryImages/14.jpg";
import image15 from "../images/GalleryImages/15.jpg";
import image16 from "../images/GalleryImages/16.jpg";
import image17 from "../images/GalleryImages/17.jpg";
import image18 from "../images/GalleryImages/18.jpg";
import image19 from "../images/GalleryImages/19.jpg";
import image20 from "../images/GalleryImages/20.jpg";
import image21 from "../images/GalleryImages/21.jpg";
import image22 from "../images/GalleryImages/22.jpg";
import image23 from "../images/GalleryImages/23.jpg";
import image24 from "../images/GalleryImages/24.jpg";
import image25 from "../images/GalleryImages/25.jpg";
import image26 from "../images/GalleryImages/26.jpg";
import image27 from "../images/GalleryImages/27.jpg";
import image28 from "../images/GalleryImages/28.jpg";
import image29 from "../images/GalleryImages/29.jpg";
import image30 from "../images/GalleryImages/30.jpg";
import image31 from "../images/GalleryImages/31.jpg";
import image32 from "../images/GalleryImages/32.jpg";
import image33 from "../images/GalleryImages/33.jpg";
import image34 from "../images/GalleryImages/34.jpg";
import image35 from "../images/GalleryImages/35.jpg";
import image36 from "../images/GalleryImages/36.jpg";
import image37 from "../images/GalleryImages/37.jpg";
import image38 from "../images/GalleryImages/38.jpg";
import image39 from "../images/GalleryImages/39.jpg";
import image40 from "../images/GalleryImages/40.jpg";
import image41 from "../images/GalleryImages/42.jpg";
import image42 from "../images/GalleryImages/41.jpg";

import image1d from "../images/GalleryImagesDownsized/1d.jpg";
import image2d from "../images/GalleryImagesDownsized/2d.jpg";
import image3d from "../images/GalleryImagesDownsized/3d.jpg";
import image4d from "../images/GalleryImagesDownsized/4d.jpg";
import image5d from "../images/GalleryImagesDownsized/5d.jpg";
import image6d from "../images/GalleryImagesDownsized/6d.jpg";
import image7d from "../images/GalleryImagesDownsized/7d.jpg";
import image8d from "../images/GalleryImagesDownsized/8d.jpg";
import image9d from "../images/GalleryImagesDownsized/9d.jpg";
import image10d from "../images/GalleryImagesDownsized/10d.jpg";
import image11d from "../images/GalleryImagesDownsized/11d.jpg";
import image12d from "../images/GalleryImagesDownsized/12d.jpg";
import image13d from "../images/GalleryImagesDownsized/13d.jpg";
import image14d from "../images/GalleryImagesDownsized/14d.jpg";
import image15d from "../images/GalleryImagesDownsized/15d.jpg";
import image16d from "../images/GalleryImagesDownsized/16d.jpg";
import image17d from "../images/GalleryImagesDownsized/17d.jpg";
import image18d from "../images/GalleryImagesDownsized/18d.jpg";
import image19d from "../images/GalleryImagesDownsized/19d.jpg";
import image20d from "../images/GalleryImagesDownsized/20d.jpg";
import image21d from "../images/GalleryImagesDownsized/21d.jpg";
import image22d from "../images/GalleryImagesDownsized/22d.jpg";
import image23d from "../images/GalleryImagesDownsized/23d.jpg";
import image24d from "../images/GalleryImagesDownsized/24d.jpg";
import image25d from "../images/GalleryImagesDownsized/25d.jpg";
import image26d from "../images/GalleryImagesDownsized/26d.jpg";
import image27d from "../images/GalleryImagesDownsized/27d.jpg";
import image28d from "../images/GalleryImagesDownsized/28d.jpg";
import image29d from "../images/GalleryImagesDownsized/29d.jpg";
import image30d from "../images/GalleryImagesDownsized/30d.jpg";
import image31d from "../images/GalleryImagesDownsized/31d.jpg";
import image32d from "../images/GalleryImagesDownsized/32d.jpg";
import image33d from "../images/GalleryImagesDownsized/33d.jpg";
import image34d from "../images/GalleryImagesDownsized/34d.jpg";
import image35d from "../images/GalleryImagesDownsized/35d.jpg";
import image36d from "../images/GalleryImagesDownsized/36d.jpg";
import image37d from "../images/GalleryImagesDownsized/37d.jpg";
import image38d from "../images/GalleryImagesDownsized/38d.jpg";
import image39d from "../images/GalleryImagesDownsized/39d.jpg";
import image40d from "../images/GalleryImagesDownsized/40d.jpg";
import image41d from "../images/GalleryImagesDownsized/41d.jpg";
import image42d from "../images/GalleryImagesDownsized/42d.jpg";

const Home = () => {
  //SCROLL HANDLER
  const scrollHandlerAbout = () => {
    window.scrollTo({ top: 1250, behavior: "smooth" });
  };
  const scrollHandlerProd = () => {
    window.scrollTo({ top: 3600, behavior: "smooth" });
  };
  const scrollHandlerCont = () => {
    window.scrollTo({ top: 11500, behavior: "smooth" });
  };

  //LANGUAGE
  const { lang } = useContext(GlobalContext);
  let aboutText = TextLibrary.find((o) => o.element === "aboutText");
  let aboutProductText = TextLibrary.find(
    (o) => o.element === "aboutProductText"
  );

  //LIGHTBOX
  const [lightBoxOpen, setLightBoxOpen] = useState(false);
  const [imgIndex, setImgIndex] = useState(0);

  const imgListOnClickHandler = (index) => {
    setImgIndex(index);
    console.log(imgIndex);
    setOpen(true);
    setLightBoxOpen(true);
  };

  //BACKDROP
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  //TEXTFIELD
  const formNameInput = useRef();
  const formMailInput = useRef();
  const formTextInput = useRef();
  const captchaRef = useRef();
  const feedbackAlertRef = useRef();

  const [active, setActive] = useState("contained");

  const [fieldErrorName, setErrorName] = useState(false);
  const [fieldErrorMail, setErrorMail] = useState(false);
  const [fieldErrorText, setErrorText] = useState(false);
  const [fieldErrorGeneral, setErrorGeneral] = useState(false);

  const [fieldHelperTextName, setFieldHelperTextName] = useState("");
  const [fieldHelperTextMail, setFieldHelperTextMail] = useState("");
  const [fieldHelperTextText, setFieldHelperTextText] = useState("");

  //FORM SUBMISSION
  const formSubmitListner = () => {
    setErrorName(false);
    setFieldHelperTextName("");
    setErrorMail(false);
    setFieldHelperTextMail("");
    setErrorText(false);
    setFieldHelperTextText("");
    setActive("disabled");
    if (captchaRef.current.getValue()) {
      $.post("https://baczko-ceramics.com/api.php", {
        mail: formMailInput.current.value,
        name: formNameInput.current.value,
        text: formTextInput.current.value,
        lang: lang,
      })
        .done((data) => {
          let msg = JSON.parse(data);
          if (msg.type === "error") {
            if (msg.place === "name") {
              setErrorName(true);
              setFieldHelperTextName(msg.message);
            }
            if (msg.place === "mail") {
              setErrorMail(true);
              setFieldHelperTextMail(msg.message);
            }
            if (msg.place === "text") {
              setErrorText(true);
              setFieldHelperTextText(msg.message);
            }
            if (msg.place === "all") {
              alert(msg.message);
            }
          }
          if (msg.type === "ok") {
            formNameInput.current.value = "";
            formMailInput.current.value = "";
            formTextInput.current.value = "";
            setOpenSnackbar(true);
          }
        })
        .fail(() => console.log("error"))
        .done(() => setActive("contained"));
    } else {
      feedbackAlertRef.severity = "error";
      setOpenSnackbarError(true);
      setActive("contained");
    }
  };

  //SNACKBAR
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [openSnackbarError, setOpenSnackbarError] = useState(false);

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  const handleCloseSnackbarError = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbarError(false);
  };

  //RENDER
  return (
    <Container className="pageBody" maxWidth={false} disableGutters>
      <Box className="landingMain">
        <Box
          component={"img"}
          src={landingLogo}
          maxHeight={{ xs: "13rem", md: "40vh" }}
          pb={{ xs: "2rem", md: "5rem" }}
        ></Box>
      </Box>
      <Box className="landingTitle" fontSize={{ xs: "3rem", md: "5rem" }}>
        BACZKO
      </Box>
      <Grid
        container
        className="divider"
        direction="column"
        justifyContent="space-between"
        alignContent="center"
      >
        <Grid item className="landingTitle">
          <Box id="titleSecondLine" fontSize={{ xs: "3rem", md: "5rem" }}>
            CERAMICS
          </Box>
        </Grid>
        <Grid
          className="divMenu"
          container
          columns={12}
          direction={{ xs: "column", md: "row" }}
          mb={{ xs: "45%", md: "14%" }}
        >
          <Grid className="menuItem" item xs={4}>
            <Box item onClick={scrollHandlerAbout}>
              {lang === "ENG" ? "About me" : "Rólam"}
            </Box>
          </Grid>
          <Grid className="menuItem" item xs={4}>
            <Box item onClick={scrollHandlerProd}>
              {lang === "ENG" ? "Products" : "Termékeim"}
            </Box>
          </Grid>
          <Grid className="menuItem" item xs={4}>
            <Box item onClick={scrollHandlerCont}>
              {lang === "ENG" ? "Contact" : "Kapcsolat"}
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <Container className="pageContent" maxWidth="xl">
        <Grid
          className="contentSection"
          container
          direction={{ xs: "column", md: "row" }}
          sx={{ justifyContent: "space-between" }}
          spacing="3rem"
        >
          <Grid className="aboutText" item xs={6}>
            <h3>{lang === "ENG" ? "About me" : "Rólam"}</h3>
            <hr />
            <p>
              {lang === "ENG" ? aboutText.eng[0] : aboutText.hun[0]}
              <br />
              <br />
              {lang === "ENG" ? aboutText.eng[1] : aboutText.hun[1]}
            </p>
          </Grid>
          <Grid
            className="aboutImgContainer"
            item
            sx={{ justifyContent: "flex-end" }}
          >
            <Box
              maxHeight={{ xs: 1, md: "30rem" }}
              maxWidth={{ xs: "100%" }}
              component="img"
              src={aboutImg}
              id="aboutImg"
            ></Box>
          </Grid>
        </Grid>
        <Grid
          className="contentSection"
          container
          direction={{ xs: "column-reverse", md: "row" }}
          sx={{ justifyContent: "space-between" }}
          spacing="3rem"
        >
          <Grid className="aboutProductImageContainer" item xs={6}>
            <Box
              component={"img"}
              maxWidth={{ xs: "100%" }}
              className="aboutProductImage"
              src={aboutProdImage}
            ></Box>
          </Grid>
          <Grid id="aboutProductText" item xs={6}>
            <h3>{lang === "ENG" ? "About my products" : "Termékeimről"}</h3>
            <hr />
            <p>
              {lang === "ENG"
                ? aboutProductText.eng[0]
                : aboutProductText.hun[0]}
              <br />
              <br />
              {lang === "ENG"
                ? aboutProductText.eng[1]
                : aboutProductText.hun[1]}
              <br />
              <br />
              {lang === "ENG"
                ? aboutProductText.eng[2]
                : aboutProductText.hun[2]}
              <br />
              <br />
              {lang === "ENG"
                ? aboutProductText.eng[3]
                : aboutProductText.hun[3]}
            </p>
          </Grid>
        </Grid>
      </Container>
      <Box className="divider" id="divProdImg">
        <h1>{lang === "ENG" ? "GALLERY" : "GALÉRIA"}</h1>
        <hr />
      </Box>
      <Container className="pageContent" maxWidth="xl">
        <hr id="galleryDiv" />
        <Box className="contentSection">
          <Suspense fallback={<div>Loading...</div>}>
            <ImageList
              sx={{ width: "100%" }}
              maxHeight={{ xs: "100vh" }}
              variant="quilted"
              cols={3}
              gap={8}
            >
              {itemDataD.map((item) => (
                <ImageListItem key={item.img} sx={{ cursor: "pointer" }}>
                  <img
                    src={`${item.img}?w=161&fit=crop&auto=format`}
                    srcSet={`${item.img}?w=161&fit=crop&auto=format&dpr=2 2x`}
                    alt={item.title}
                    loading="lazy"
                    onClick={() =>
                      imgListOnClickHandler(itemDataD.indexOf(item))
                    }
                  />
                </ImageListItem>
              ))}
            </ImageList>
          </Suspense>
          <Backdrop open={open} sx={{ zIndex: "5" }}></Backdrop>
          <Lightbox
            images={itemDataTest}
            isOpen={lightBoxOpen}
            isOpenSet={setLightBoxOpen}
            onClose={handleClose}
            imgIndex={imgIndex}
            setImgIndex={setImgIndex}
          ></Lightbox>
        </Box>
        <hr id="galleryDiv" />
      </Container>
      <Box className="divider" id="divContProd">
        <h1>{lang === "ENG" ? "CONTACT" : "KAPCSOLAT"}</h1>
        <hr />
      </Box>
      <Container className="pageContent" maxWidth="xl">
        <div className="contentSection">
          <Box marginBottom={"4rem"}>
            <h3>
              {lang === "ENG"
                ? "In case you are interested, do not hesitate to contact me!"
                : "Amennyiben felkeltettem az érdeklődését, kérem írjon bizalommal!"}
            </h3>
            <p>
              {lang === "ENG"
                ? "My products can be purchased in person at my workshop in Szeged, or I can send them by post , courier service or to a parcel machine according to the current rates."
                : "Termékeim a szegedi műhelyemben személyesen is megvásárolhatók,illetve a mindenkori díjszabások szerint postán, futárszolgálattal vagy akár csomagautomatába is tudom küldeni őket."}
              <br />
              {lang === "ENG"
                ? "Postage will occur within two to three business days after the payment arrives in my account."
                : "A postázás a vételár és a szállítási költség számlámontörténő megjelenését követően két-három munknapon belül történik."}
            </p>
          </Box>
          <Box
            component="form"
            sx={{ width: "100%" }}
            noValidate
            autoComplete="off"
            className="formSection"
          >
            <TextField
              required
              id="outlined-required"
              label={lang === "ENG" ? "Name" : "Név"}
              defaultValue=""
              inputRef={formNameInput}
              error={fieldErrorName}
              helperText={fieldHelperTextName}
            />
            <TextField
              required
              id="outlined-required"
              label="e-Mail"
              defaultValue=""
              inputRef={formMailInput}
              error={fieldErrorMail}
              helperText={fieldHelperTextMail}
            />
            <TextField
              id="outlined-multiline-static"
              label={lang === "ENG" ? "Text" : "Szöveg"}
              multiline
              fullWidth
              rows={10}
              defaultValue=""
              inputRef={formTextInput}
              error={fieldErrorText}
              helperText={fieldHelperTextText}
            />
            <ReCAPTCHA
              sitekey="6Ld_jb4gAAAAAKiNLCydHoxu5BEN3D8NLjLyYTIu"
              render="explicit"
              ref={captchaRef}
            />
            <Button variant={active} onClick={formSubmitListner}>
              {lang === "ENG" ? "SEND" : "ELKÜLD"}
            </Button>
          </Box>
          <Snackbar
            open={openSnackbar}
            autoHideDuration={6000}
            onClose={handleCloseSnackbar}
          >
            <Alert
              severity="success"
              onClose={handleCloseSnackbar}
              width="100%"
            >
              {lang === "ENG"
                ? "Message sent successfuly!"
                : "Sikeres üzenetküldés!"}
            </Alert>
          </Snackbar>
          <Snackbar
            open={openSnackbarError}
            autoHideDuration={6000}
            onClose={handleCloseSnackbarError}
          >
            <Alert
              severity="error"
              onClose={handleCloseSnackbarError}
              width="100%"
            >
              {lang === "ENG"
                ? "reCaptcha must be filled!"
                : "A captcha-t kötelező kitölteni!"}
            </Alert>
          </Snackbar>
        </div>
      </Container>
    </Container>
  );
};

const itemDataD = [
  {
    img: image1d,
    title: "",
  },
  {
    img: image2d,
    title: "",
  },
  {
    img: image3d,
    title: "",
  },
  {
    img: image4d,
    title: "",
  },
  {
    img: image5d,
    title: "",
  },
  {
    img: image6d,
    title: "",
  },
  {
    img: image7d,
    title: "",
  },
  {
    img: image8d,
    title: "",
  },
  {
    img: image9d,
    title: "",
  },
  {
    img: image10d,
    title: "",
  },
  {
    img: image11d,
    title: "",
  },
  {
    img: image12d,
    title: "",
  },
  {
    img: image13d,
    title: "",
  },
  {
    img: image14d,
    title: "",
  },
  {
    img: image15d,
    title: "",
  },
  {
    img: image16d,
    title: "",
  },
  {
    img: image17d,
    title: "",
  },
  {
    img: image18d,
    title: "",
  },
  {
    img: image19d,
    title: "",
  },
  {
    img: image20d,
    title: "",
  },
  {
    img: image21d,
    title: "",
  },
  {
    img: image22d,
    title: "",
  },
  {
    img: image23d,
    title: "",
  },
  {
    img: image24d,
    title: "",
  },
  {
    img: image25d,
    title: "",
  },
  {
    img: image26d,
    title: "",
  },
  {
    img: image27d,
    title: "",
  },
  {
    img: image28d,
    title: "",
  },
  {
    img: image29d,
    title: "",
  },
  {
    img: image30d,
    title: "",
  },
  {
    img: image31d,
    title: "",
  },
  {
    img: image32d,
    title: "",
  },
  {
    img: image33d,
    title: "",
  },
  {
    img: image34d,
    title: "",
  },
  {
    img: image35d,
    title: "",
  },
  {
    img: image36d,
    title: "",
  },
  {
    img: image37d,
    title: "",
  },
  {
    img: image38d,
    title: "",
  },
  {
    img: image39d,
    title: "",
  },
  {
    img: image40d,
    title: "",
  },
  {
    img: image41d,
    title: "",
  },
  {
    img: image42d,
    title: "",
  },
];

const itemDataTest = [
  {
    src: image1,
    loading: "lazy",
    alt: "",
  },
  {
    src: image2,
    loading: "lazy",
    alt: "",
  },
  {
    src: image3,
    loading: "lazy",
    alt: "",
  },
  {
    src: image4,
    loading: "lazy",
    alt: "",
  },
  {
    src: image5,
    loading: "lazy",
    alt: "",
  },
  {
    src: image6,
    loading: "lazy",
    alt: "",
  },
  {
    src: image7,
    loading: "lazy",
    alt: "",
  },
  {
    src: image8,
    loading: "lazy",
    alt: "",
  },
  {
    src: image9,
    loading: "lazy",
    alt: "",
  },
  {
    src: image10,
    loading: "lazy",
    alt: "",
  },
  {
    src: image11,
    loading: "lazy",
    alt: "",
  },
  {
    src: image12,
    loading: "lazy",
    alt: "",
  },
  {
    src: image13,
    loading: "lazy",
    alt: "",
  },
  {
    src: image14,
    loading: "lazy",
    alt: "",
  },
  {
    src: image15,
    loading: "lazy",
    alt: "",
  },
  {
    src: image16,
    loading: "lazy",
    alt: "",
  },
  {
    src: image17,
    loading: "lazy",
    alt: "",
  },
  {
    src: image18,
    loading: "lazy",
    alt: "",
  },
  {
    src: image19,
    loading: "lazy",
    alt: "",
  },
  {
    src: image20,
    loading: "lazy",
    alt: "",
  },
  {
    src: image21,
    loading: "lazy",
    alt: "",
  },
  {
    src: image22,
    loading: "lazy",
    alt: "",
  },
  {
    src: image23,
    loading: "lazy",
    alt: "",
  },
  {
    src: image24,
    loading: "lazy",
    alt: "",
  },
  {
    src: image25,
    loading: "lazy",
    alt: "",
  },
  {
    src: image26,
    loading: "lazy",
    alt: "",
  },
  {
    src: image27,
    loading: "lazy",
    alt: "",
  },
  {
    src: image28,
    loading: "lazy",
    alt: "",
  },
  {
    src: image29,
    loading: "lazy",
    alt: "",
  },
  {
    src: image30,
    loading: "lazy",
    alt: "",
  },
  {
    src: image31,
    loading: "lazy",
    alt: "",
  },
  {
    src: image32,
    loading: "lazy",
    alt: "",
  },
  {
    src: image33,
    loading: "lazy",
    alt: "",
  },
  {
    src: image34,
    loading: "lazy",
    alt: "",
  },
  {
    src: image35,
    loading: "lazy",
    alt: "",
  },
  {
    src: image36,
    loading: "lazy",
    alt: "",
  },
  {
    src: image37,
    loading: "lazy",
    alt: "",
  },
  {
    src: image38,
    loading: "lazy",
    alt: "",
  },
  {
    src: image39,
    loading: "lazy",
    alt: "",
  },
  {
    src: image40,
    loading: "lazy",
    alt: "",
  },
  {
    src: image41,
    loading: "lazy",
    alt: "",
  },
  {
    src: image42,
    loading: "lazy",
    alt: "",
  },
];

export default Home;
