import React from "react";
import Lightbox from "react-spring-lightbox";
import { useState } from "react";
import LightBoxArrow from "./LightboxArrow";

const LightboxComp = (props) => {
  const canPrev = props.imgIndex > 0;
  const canNext = props.imgIndex + 1 < props.images.length;

  const gotoNext = () => {
    canNext
      ? props.setImgIndex(props.imgIndex + 1)
      : props.setImgIndex(props.imgIndex);
  };

  const gotoPrevious = () => {
    canPrev
      ? props.setImgIndex(props.imgIndex - 1)
      : props.setImgIndex(props.imgIndex);
  };

  const onClose = () => {
    props.isOpenSet(false);
    props.onClose(false);
  };
  return (
    <Lightbox
      isOpen={props.isOpen}
      onPrev={gotoPrevious}
      onNext={gotoNext}
      images={props.images}
      currentIndex={props.imgIndex}
      onClose={onClose}
      singleClickToZoom
      renderNextButton={() => <LightBoxArrow right={true} goto={gotoNext} />}
      renderPrevButton={() => (
        <LightBoxArrow right={false} goto={gotoPrevious} />
      )}
      /* Add your own UI */
      // renderHeader={() => (<CustomHeader />)}
      // renderFooter={() => (<CustomFooter />)}
      // renderPrevButton={() => (<CustomLeftArrowButton />)}
      // renderNextButton={() => (<CustomRightArrowButton />)}
      // renderImageOverlay={() => (<ImageOverlayComponent >)}

      /* Add styling */
      // className="cool-class"
      // style={{ background: "grey" }}

      /* Handle closing */
      // onClose={handleClose}

      /* Use single or double click to zoom */
      // singleClickToZoom

      /* react-spring config for open/close animation */
      // pageTransitionConfig={{
      //   from: { transform: "scale(0.75)", opacity: 0 },
      //   enter: { transform: "scale(1)", opacity: 1 },
      //   leave: { transform: "scale(0.75)", opacity: 0 },
      //   config: { mass: 1, tension: 320, friction: 32 }
      // }}
    />
  );
};

export default LightboxComp;
