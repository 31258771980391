import React from "react"
import "./Footer.css"

const Footer = () => {
  return (
    <footer className="footerBody">
      <div className="footerContent">Copyright - Baczko Ceramics</div>
    </footer>
  )
}

export default Footer
