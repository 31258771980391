import React from "react";

const TextLibrary = [
  {
    element: "aboutText",
    hun: [
      `Az agyaggal való foglalkozás végigkíséri az életemet
    gyerekkoromtól fogva. Első sikerem: általános iskolásként
    tengerparti táborozást nyertem egy kiállításon. 1983-ban
    érettségiztem a Tömörkény István Művészeti Szakközépiskola kerámia
    tagozatán. 1987-ben diplomáztam a Juhász Gyula Tanárképző Főiskola
    földrajz-rajz szakán.`,
      `Hosszú évekig tanítottam általános
    iskolában, alapfokú művészeti iskolában, foglalkoztam gyerekekkel
    és felnőttekkel egyaránt. A neuropsychiátrián betegekkel is.
    Mindeközben magam is folyton dolgoztam, külföldön és
    Magyarországon számtalan csoportos kiállításon vettem részt, vagy
    önálló kiállításon mutattam be munkáimat.`,
    ],
    eng: [
      `Working with clay has been a part of my life since childhood. My very first success came when I was a
    primary school student: winning a beach camp holiday after I exhibited my product. I graduated from
    Tömörkény István Secondary Grammar School and Secondary School of Arts in 1983, where I was
    specialized in Ceramics. Following that, I graduated from Juhász Gyula Faculty of Education at the
    University of Szeged in 1987, majoring in Geography and Arts.`,
      ` I have been a teacher in primary
    schools and primary art schools for many years, working with both children and adults. I also worked
    with neuropsychiatric patients. In the meantime, I was constantly making ceramics at home too, and
    participated in many group exhibitions, both abroad and in Hungary, as well as doing countless solo
    exhibitions.`,
    ],
  },
  {
    element: "aboutProductText",
    hun: [
      `A természeti képződmények, mint a falevelek, virágok, termések,
    kavicsok egyediek, megismételhetetlenül egyszeriek. Fantáziámból,
    belső világomból hogyan tudom megmutatni az egyediben az
    általánost? A típusban az egyediséget? A természetben található
    változatos formákból, ezek belső szerkezetének harmóniájából
    építkezem.`,
      `
    Tárgyaimat - melyeknek mindig van funkciója - kézzel mintázom. Az
    agyag kimeríthetetlen, izgalmas anyag. A készülő tárgy az utolsó
    percig, az utolsó munkafolyamatig változhat. Munkáimat megtervezem
    ugyan, de amikor alkotok, mindig új lehetőségek tárulnak fel
    előttem.`,
      `
    Kedves színem a kék, a zöld, valamint azok árnyalatai: a vizek, a
    pára, az ég, a növények színei.`,
      `
    Remélem a látogatónak is akkora örömet okoznak ezek a tárgyak, mint nekem, az
    alkotónak, aki készítette őket.`,
    ],
    eng: [
      `Things in nature such as leaves, flowers, fruits, and pebbles are unique and unrepeatable. How can
    my imagination present the typical in a unique form, and the unique in a typical form?`,
      ` I get
    inspiration from the various forms found in nature, from the harmony of their internal structure.
    My ceramic products are handmade, and they are always functional.`,
      ` Clay is an inexhaustible, exciting
    material. During the work process, the product can even change in the last minute, in the last stage
    of the process. I plan my work, but when I create, new possibilities always open up for me.`,
      `
    My favourite colours are blue, green and their shades: the colours of water, mist, the sky, and plants.`,
      `
    I hope they bring you as much joy as they did to me, the artist who created them.`,
    ],
  },
];

export default TextLibrary;
