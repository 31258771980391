import React from "react";
import { IconButton } from "@mui/material";
import { ArrowBackIosNew } from "@mui/icons-material";

const LightBoxArrow = (props) => {
  return (
    <IconButton
      sx={{ marginRight: "auto", zIndex: "6" }}
      size={"large"}
      onClick={props.goto}
    >
      <ArrowBackIosNew
        fontSize="large"
        sx={[
          { zIndex: "6", color: "white" },
          props.right && { transform: "scaleX(-1)" },
        ]}
      />
    </IconButton>
  );
};

export default LightBoxArrow;
