import { useEffect, useState } from "react";
import ReactGA from "react-ga";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Home from "./pages/Home";
import GlobalContext from "./Store/Context";
const TRACKING_ID = "UA-233533318-1"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

function App() {
	var userLang = navigator.language || navigator.userLanguage;
	let initLang = "ENG";
	useEffect(() => {
		ReactGA.pageview(window.location.pathname + window.location.search);
	}, []);

	useEffect(() => {
		if (userLang !== "hu") {
			setLang("ENG");
		} else {
			setLang("HUN");
		}
	}, []);

	const [lang, setLang] = useState(initLang);

	return (
		<GlobalContext.Provider value={{ lang, setLang }}>
			<BrowserRouter>
				<Header></Header>
				<main>
					<Routes>
						<Route path="/home" element={<Home />} />
						<Route path="/*" element={<Home />} />
					</Routes>
				</main>
				<Footer></Footer>
			</BrowserRouter>
		</GlobalContext.Provider>
	);
}

export default App;
